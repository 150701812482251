import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patient",
  initialState: {
    patient_id: "", // remove 1 and make it empty on final deploying
    status: "",
    profile_url: "",
    name: "",
    email: "",
    age: 0,
    gender: "",
    contact_number: "",
    country: "",
  },
  reducers: {
    setPatient: (state, action) => {
      return action.payload;
    },
    updatePatientName: (state, action) => {
      state.name = action.payload;
      return state;
    },
    updatePatientContact: (state, action) => {
      state.contact_number = action.payload;
      return state;
    },
    updatePatientAge: (state, action) => {
      state.age = action.payload;
      return state;
    },
    updatePatientGender: (state, action) => {
      state.gender = action.payload;
      return state;
    },
    updatePatientProfileURL: (state, action) => {
      state.profile_url = action.payload;
      return state;
    },
    updatePatientCountry: (state, action) => {
      state.country = action.payload;
      return state;
    },
    updatePatientEmail: (state, action) => {
      state.email = action.payload;
      return state;
    },
    logoutResetState: (state) => {},
  },
});

export const {
  updatePatientName,
  updatePatientContact,
  updatePatientAge,
  updatePatientGender,
  updatePatientProfileURL,
  setPatient,
  updatePatientCountry,
  updatePatientEmail,
  logoutResetState,
} = patientSlice.actions;

export default patientSlice.reducer;
