import "./SettingDetails.css";

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  updatePatientAge,
  updatePatientContact,
  updatePatientCountry,
  updatePatientGender,
  updatePatientName,
  updatePatientProfileURL,
} from "../../../store/patient/patientSlice";
import {
  updatePatientDetailsAPI,
  getProfilePictureUpdatePresignedUrlAPI,
  updateProfilePictureAPI,
} from "../../../api/patient";
import { getUserFilesUploadURL, uploadFiles } from "../../../api/s3";
import { toast } from "react-hot-toast";
import PasswordSettings from "./PasswordSettings";
import { Autocomplete, Box, TextField } from "@mui/material";
import { iso31661 } from "iso-3166";
import { MuiTelInput } from "mui-tel-input";
import EmailSettings from "./EmailSettings";

const ProfileSettings = () => {
  const patient = useSelector((store) => store.patient);
  const [profilePicChangeEnabled, setProfilePicChangeEnabled] = useState(false);

  const updateUserDetails = async () => {
    try {
      const response = await updatePatientDetailsAPI(
        patient.name,
        patient.email,
        patient.contact_number,
        patient.age,
        patient.gender,
        patient.country
      );

      if (response.status === 200) {
        toast.success("Saved successfully");
      }
    } catch (e) {
      toast.error("Saved failed");
    }
  };

  const uploadFile = async (event) => {
    try {
      const presignedRes = await getUserFilesUploadURL(
        patient.patient_id,
        event.target.files[0].name.split(".").pop()
      );

      const presigned = presignedRes.data;

      console.log(presigned);
      console.log(event.target.files[0]);

      await uploadFiles(presigned.url, event.target.files[0]);

      await updateProfilePictureAPI(
        patient.email,
        presigned.bucket,
        presigned.key
      );
      dispatch(
        updatePatientProfileURL(URL.createObjectURL(event.target.files[0]))
      );
      toast.success("Profile picture uploaded successfully");
    } catch (e) {
      console.log(e);
      toast.error("Profile picture uploaded failed");
    }
  };

  const dispatch = useDispatch();

  return (
    <div className="profile-root">
      <div
        className="profile-display"
        onClick={() => {
          setProfilePicChangeEnabled(true);
        }}
      >
        <div className="image-cropper">
          <img
            // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
            src={
              patient.profile_url === ""
                ? `https://ui-avatars.com/api/?name=${patient.name}&background=random&rounded=true`
                : patient.profile_url
            }
            className="profile-picture-big"
            alt="profile"
          />
        </div>
        <div className="profile-display-info">
          <span className="heading">{patient.name}</span>
          <span className="info-text">{patient.email}</span>
        </div>
        {profilePicChangeEnabled && (
          <div className="profilepic-update-menu">
            <input type="file" id="profileInput" onChange={uploadFile} />
            <label htmlFor="profileInput" className="yellow-button">
              Choose File
            </label>
          </div>
        )}
      </div>
      <div className="settings-input-div">
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Full Name</span>
            <input
              className="normal-input"
              value={patient.name ? patient.name : ""}
              onChange={(e) => {
                dispatch(updatePatientName(e.target.value));
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Contact</span>

            {/* <input
              type="tel"
              className="normal-input"
              value={patient.contact_number ? patient.contact_number : ""}
              onChange={(e) => {
                let inputValue = e.target.value;
                inputValue = inputValue.replace(/[^0-9+()-]/g, ''); // Allow only digits, plus sign, hyphen, parentheses
                dispatch(updatePatientContact(inputValue));
              }}
            /> */}
            <MuiTelInput
              // defaultCountry={patient.country ? patient.country : ""}
              className="normal-input test"
              value={patient.contact_number ? patient.contact_number : ""}
              onChange={(newPhone) => {
                dispatch(updatePatientContact(newPhone));
              }}
            />
          </div>
        </div>
      </div>
      <div className="settings-input-div">
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Age</span>

            <input
              type="number"
              className="normal-input"
              value={patient.age ? patient.age : ""}
              onChange={(e) => {
                dispatch(updatePatientAge(e.target.value));
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Gender</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="normal-dropdown"
              value={patient.gender ? patient.gender.toLowerCase() : ""}
              label="gender"
              onChange={(e) => {
                dispatch(updatePatientGender(e.target.value));
              }}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"others"}>Others</MenuItem>
              <MenuItem value={null}>Prefer not to say</MenuItem>
            </Select>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Country</span>
            {/* <FormControl fullWidth style={{ width: "24rem", border: 0 }}> */}
            <Autocomplete
              size="small"
              id="country-select-demo"
              sx={{ width: "24rem" }}
              options={iso31661}
              autoHighlight
              getOptionLabel={(option) => option.name}
              value={
                patient?.country
                  ? iso31661.find(
                      (country) => country.alpha2 === patient.country
                    )
                  : null
              }
              onChange={(event, newValue) => {
                dispatch(updatePatientCountry(newValue?.alpha2));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.name} ({option.alpha2})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "solid 1.4px #aaa",
                    },
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {/* </FormControl> */}
          </div>
        </div>
      </div>

      <div className="sized-box" />
      <div
        className="blue-button"
        onClick={() => {
          updateUserDetails();
        }}
      >
        Save
      </div>
    </div>
  );
};

export default function SettingDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSetting = searchParams.get("tab");

  return (
    <div className="setting-details-wrapper">
      {currentSetting === "profile" && <ProfileSettings />}
      {currentSetting === "password" && <PasswordSettings />}
      {currentSetting === "email" && <EmailSettings />}
    </div>
  );
}
