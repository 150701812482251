import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getPatientByEmailAPI = async (patientEmail) => {
  const baseURL = config.API_URL;
  const path = "/patient";
  const params = "?patient_email=" + encodeURIComponent(patientEmail);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getPatientByEmailAPIv2 = async (patientEmail) => {
  // does not contain any therapy ID
  const baseURL = config.API_URL;
  const path = "/v2/patient";
  const params = "?patient_email=" + encodeURIComponent(patientEmail);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getPatientDashboardByEmailAPI = async (patientEmail) => {
  const baseURL = config.API_URL;
  const path = "/patient/dashboard";
  const params = "?patient_email=" + encodeURIComponent(patientEmail);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getPatientDashboardByTherapyIdAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/v2/patient/dashboard";
  const params = "?therapy_id=" + encodeURIComponent(therapy_id);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const updateTherapistRatingByPatientAPI = async (therapyId, rating) => {
  const baseURL = config.API_URL;
  const path = "/therapy/rating";
  const body = {
    therapy_id: therapyId,
    rating,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getUploadFilePresignedUrlAPI = async (therapyId, extension) => {
  const baseURL = config.API_URL;
  const path = "/therapy/file/presigned_url";
  const params = "?therapy_id=" + therapyId + "&extension=" + extension;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const createNewFileByTherapyAPI = async (
  therapyId,
  filename,
  size,
  bucketName,
  bucketKey,
  authorName,
  patientId,
  authorType = "PATIENT"
) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files";
  const body = {
    therapy_id: therapyId,
    filename: filename,
    size: size,
    bucket_name: bucketName,
    bucket_key: bucketKey,
    author_name: authorName,
    author_type: authorType,
    author_id: patientId,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getTherapyFilesAPI = async (therapyId) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files";
  const params = "?therapy_id=" + therapyId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateTherapyFilePinnedStatusAPI = async (fileId, isPinned) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files/pinned";
  const body = {
    file_id: fileId,
    is_pinned: isPinned,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateTherapyFilenameAPI = async (
  fileId,
  filename,
  authorName
) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files/filename";
  const body = {
    file_id: fileId,
    filename: filename,
    author_type: "PATIENT",
    author_name: authorName,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const deleteTherapyFileByIdAPI = async (fileId, authorName) => {
  const baseURL = config.API_URL;
  const path = `/therapy/file?file_id=${fileId}&author_name=${encodeURIComponent(
    authorName
  )}&author_type=PATIENT`;
  // const params = "?file_id=" + fileId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.delete(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updatePatientDetailsAPI = async (
  name,
  email,
  contact_number,
  age,
  gender,
  country
) => {
  const baseURL = config.API_URL;
  const path = "/patient";
  const body = {
    name,
    email,
    contact_number,
    age,
    gender,
    country,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getProfilePictureUpdatePresignedUrlAPI = async (
  patientId,
  extension
) => {
  const baseURL = config.API_URL;
  const path = "/patient/profile_picture/presigned_url";
  const params = "?patient_id=" + patientId + "&extension=" + extension;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateProfilePictureAPI = async (email, bucket, key) => {
  const baseURL = config.API_URL;
  const path = "/patient/profile_picture";
  const body = {
    email,
    bucket,
    key,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getLatestTherapyTasksByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks/latest";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getAllTherapyTasksByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getAllTherapistByPatientEmail = async (patientEmail) => {
  const baseURL = config.API_URL;
  const path = "/patient/list_all_therapists";
  const params = "?patient_email=" + encodeURIComponent(patientEmail);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  console.log("newer-user", idToken);
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const postTherapyTaskByTherapyId = async (therapy_id, task) => {
  console.log(therapy_id, task);
  const baseURL = config.API_URL;
  const path = "/therapy_tasks";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    `${baseURL + path}`,
    JSON.stringify({ therapy_id, task }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const updateTaskStatusById = async (task_id, status) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks/status";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(
    `${baseURL + path}`,
    JSON.stringify({ task_id, status }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const deleteTaskById = async (task_id) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks";
  const params = "?task_id=" + task_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.delete(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const updateTherapyStatusAPI = async (therapy_id, status) => {
  const baseURL = config.API_URL;
  const path = "/therapy/status";
  const body = {
    therapy_id: therapy_id,
    status: status,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updatePatientEmailAPI = async (old_email, updated_email) => {
  const baseURL = config.API_URL;
  const path = "/patient_email";
  const body = {
    old_email: old_email,
    updated_email: updated_email,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};
