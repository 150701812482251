import "./UpcomingSession.css";

import { useEffect, useState } from "react";

import ASSETS from "../../../../../assets/Assets";

import RequestSessionRescheduleModal from "../requestSessionRescheduleModal/RequestSessionRescheduleModal";
import { useSelector } from "react-redux";

import { format } from "date-fns";

function UpcomingSession() {
  const upcomingSession = useSelector((store) => store.client.upcoming_session);
  const therapistEmail = useSelector((store) => store.client.therapist.email);

  const [isMeetingActive, setIsMeetingActive] = useState(false);

  useEffect(() => {
    if (upcomingSession.startDatetime) {
      const date = new Date();
      if (
        date >= new Date(upcomingSession.startDatetime) &&
        date <= new Date(upcomingSession.endDatetime)
      ) {
        setIsMeetingActive(true);
      }
    }
  }, [upcomingSession]);

  const [
    requestSessionRescheduleModalIsShown,
    setRequestSessionRescheduleModalIsShown,
  ] = useState(false);

  return (
    <>
      {requestSessionRescheduleModalIsShown && (
        <RequestSessionRescheduleModal
          session={upcomingSession}
          therapistEmail={therapistEmail}
          onClose={() => setRequestSessionRescheduleModalIsShown(false)}
        />
      )}
      <div className="upcoming-session-container">
        <div className="upcoming-session-content">
          <div className="upcoming-session-subheading-group row">
            <div className="upcoming-session-calendar">
              <img
                src={ASSETS.upcomingCalendarSession}
                alt="upcoming session"
              />
            </div>
            <div className="upcoming-session-subheading">Upcoming Session</div>
          </div>

          {upcomingSession.startDatetime ? (
            <div className="upcoming-session-timeline row">
              <div className="upcoming-session-date-group row">
                <div className="upcoming-session-date-icon">
                  <img
                    src={ASSETS.upcomingSessionDate}
                    alt="upcoming session date"
                  />
                </div>
                <div className="upcoming-session-date">
                  {upcomingSession.startDatetime &&
                    format(new Date(upcomingSession.startDatetime), "d MMM y")}
                </div>
              </div>
              <div className="upcoming-session-time-group row">
                <div className="upcoming-session-time-icon">
                  <img
                    src={ASSETS.upcomingSessionTime}
                    alt="upcoming session time"
                  />
                </div>
                <div className="upcoming-session-time">
                  {upcomingSession.startDatetime &&
                    format(
                      new Date(upcomingSession.startDatetime),
                      "hh:mm aaa"
                    )}
                </div>
              </div>
            </div>
          ) : (
            <div className="info-text upcoming-session-info-text">
              {" "}
              You don't have scheduled sessions yet.{" "}
            </div>
          )}
        </div>
        <div className="upcoming-session-action-buttons row">
          {upcomingSession.startDatetime ? (
            <div className="upcoming-session-button-group">
              {/* <div
                className="upcoming-session-reschedule-button"
                onClick={() => setRequestSessionRescheduleModalIsShown(true)}
              >
                Reschedule
              </div> */}
              {isMeetingActive ? (
                <div
                  className="upcoming-session-reschedule-button"
                  onClick={() =>
                    window.open(upcomingSession.meetingLink, "_blank")
                  }
                >
                  Join
                </div>
              ) : (
                <div className="upcoming-session-reschedule-button button-disabled">
                  Join
                </div>
              )}
            </div>
          ) : (
            <div className="upcoming-session-button-group">
              {/* <div className="upcoming-session-reschedule-button button-disabled">
                Reschedule
              </div> */}
              <div className="upcoming-session-reschedule-button button-disabled">
                Join
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UpcomingSession;
