import "./Tasks.css";

import ASSETS from "../../../../../assets/Assets";
import { useDispatch, useSelector } from "react-redux";
import { Task } from "../../../../report/components/tasks/Tasks";
import {
  deleteTaskById,
  getAllTherapyTasksByTherapyId,
  getLatestTherapyTasksByTherapyId,
  updateTaskStatusById,
} from "../../../../../api/patient";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  removeTaskByIdx,
  setTasks,
  updateCheckTask,
} from "../../../../../store/report/reportSlice";

function Tasks() {
  const therapy_id = useSelector((store) => store.client.therapyId);
  const patient_id = useSelector((store) => store.patient.patient_id);

  // let [tasks, setTasks] = useState([]);
  let tasks = useSelector((store) => store.report.tasks);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    getAllTherapyTasksByTherapyId(therapy_id).then((res) => {
      let all_tasks = res.all_therapy_tasks.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      dispatch(setTasks(all_tasks));
    });
  }, []);

  function onDeleteButtonClick(idx) {
    deleteTaskById(tasks[idx].task_id).then((res) => {
      dispatch(
        removeTaskByIdx({
          idx,
        })
      );
    });
  }

  function onCheckboxTick(task_id, idx) {
    const status = tasks[idx].status ? 0 : 1;
    updateTaskStatusById(task_id, status).then((res) => {
      dispatch(
        updateCheckTask({
          idx,
          status,
        })
      );
    });
  }

  const totalTasks = tasks.length;
  const completedTasks = tasks.filter((element) => element.status).length;
  const remainingTasks = totalTasks - completedTasks;

  return (
    <div className="clients-tasks-container scroll-thin">
      <div className="clients-tasks-subheading">Your Tasks</div>
      <div className="tasks-metrics-wrapper">
        <div className="clients-task-metrics-container">
          <div className="client-task-metric">
            <img src={ASSETS.totalTasks} alt="total tasks" />
            <div className="client-task-metric-data-container">
              <div className="client-task-metric-subheading">
                Total Tasks Given
              </div>
              <div className="client-task-metric-value">{totalTasks}</div>
            </div>
          </div>
          <div className="client-task-metric">
            <img src={ASSETS.tasksCompleted} alt="tasks completed" />
            <div className="client-task-metric-data-container">
              <div className="client-task-metric-subheading">
                Tasks Completed
              </div>
              <div className="client-task-metric-value client-tasks-completed-value">
                {completedTasks}
              </div>
            </div>
          </div>
          <div className="client-task-metric">
            <img src={ASSETS.tasksIncomplete} alt="tasks incomplete" />
            <div className="client-task-metric-data-container">
              <div className="client-task-metric-subheading">
                Task Incomplete
              </div>
              <div className="client-task-metric-value client-tasks-incomplete-value">
                {remainingTasks}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-tasks-container scroll-thin">
          <div className="row" style={{ height: "2rem" }}>
            <div className="dashboard-tasks-subheading">
              Recent Tasks & Advices
            </div>
            <Link to="/report/tasks">
              <div className="dashboard-tasks-show-all">Show All</div>
            </Link>
          </div>
          <div className="report-tasks-list">
            {tasks.slice(0, 3).map((task, idx) => {
              if (idx < 3) {
                return (
                  <Task
                    idx={idx}
                    task={task}
                    onCheckboxTick={onCheckboxTick}
                    onDeleteButtonClick={onDeleteButtonClick}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
