import React from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ASSETS from "../../../../assets/Assets";
import { Divider } from "@mui/material";

function FileMenu({ onDelete, onRename }) {
  return (
    <MenuList sx={{ width: 200, maxWidth: "100%" }}>
      <MenuItem onClick={onRename}>
        <ListItemIcon>
          <img src={ASSETS.rename} alt="pencil" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <img src={ASSETS.deleteIcon} alt="delete" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </MenuList>
  );
}

export default FileMenu;
