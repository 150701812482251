import "./Overview.css";

import { useEffect } from "react";
import TimeAgo from "react-timeago";

import ASSETS from "../../../../assets/Assets";

import PinnedFiles from "../pinnedFiles/PinnedFiles";
import { template } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getTherapyFilesAPI } from "../../../../api/patient";
import { setFiles } from "../../../../store/report/reportSlice";
import { fetchAllActivitiesAPI } from "../../../../api/activities";
import { setActivities } from "../../../../store/activities/activitySlice";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

export function LatestActivity(props) {
  const activity = props.activity;
  console.log("activity ---------", activity);

  return (
    <>
      <div className="overview-subheading overview-latest-activity">
        Latest Activity
      </div>

      <div className="overview-latest-activity-logs">
        {activity.map((act, idx) => {
          console.log(act);
          const message = template(act.template);
          return (
            <div key={idx}>
              <div className="over-latest-activity row justify-flex-start">
                <img src={act.icon} alt="text snippet" />
                <div className="overview-latest-activity-log">
                  {message(act.attributes)}

                  <div className="activity-log-timestamp">
                    <TimeAgo
                      date={mysqlDatetimeToJavascriptDatetime(act.log_time)}
                    />
                  </div>
                </div>
              </div>
              {idx < activity.length - 1 && (
                <div className="overview-latest-activity-seperator"></div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

function Overview() {
  const { activities } = useSelector((store) => store.activities);
  const therapyId = useSelector((store) => store.client.therapyId);
  const dispatch = useDispatch();

  useEffect(() => {
    getTherapyFilesAPI(therapyId).then((res) => {
      const files = res.data.map((file) => {
        return {
          fileId: file.file_id,
          filename: file.filename,
          uploaded_at: file.uploaded_at_iso,
          fileUrl: file.file_url,
          size: file.size,
          author: file.author_profile_url,
          pinned: file.pinned !== 0,
        };
      });
      dispatch(setFiles(files));
    });
    fetchAllActivitiesAPI(therapyId).then((data) => {
      dispatch(setActivities(data));
    });
  }, []);

  return (
    <>
      <PinnedFiles heading={"Pinned Files"} showAllBtn={true} />
      <LatestActivity activity={activities} />
      {/* <div className="overview-subheading overview-latest-activity">Latest Activity</div>

    <div className="overview-latest-activity-logs">
      {
        activity.map((act, idx) => {
          return <div key={idx}>
            <div className="over-latest-activity row justify-flex-start">
              <img src={ASSETS.textSnippet} alt="text snippet" />
              <div className="overview-latest-activity-log">
                { act.author } <span className="activity-log-info-text">{ act.action }</span> { act.actionItem }
                <div className="activity-log-timestamp">
                  <TimeAgo datetime={new Date(act.timestamp)} />
                </div>
              </div>
            </div>

            { idx < activity.length - 1 && <div className="overview-latest-activity-seperator"></div> }
          </div>;
        })
      }
    </div> */}
    </>
  );
}

export default Overview;
