import "./TherapistList.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TherapistInfoTile from "./TherapistInfoTile/TherapistInfoTile";
import TherapistHeader from "./TherapistHeader/TherapistHeader";
import { getAllTherapistByPatientEmail } from "../../api/patient";

const NotExist = () => {
  return (
    <div className="not-exist">
      <div className="not-exist-text">
        It seems no therapy associated with this email address. Contact your
        therapists to create one therapy associated with this email or login
        with another email which have therapy associated with it.
      </div>
    </div>
  );
};

const TherapistList = () => {
  const [therapists, setTherapists] = useState(null);
  const patient = useSelector((store) => store.patient);
  console.log(patient.email);

  useEffect(() => {
    getAllTherapistByPatientEmail(patient.email).then((res) => {
      console.log("The therapists for this account are: ", res);
      setTherapists(res);
    });
  }, []);

  return (
    <div className="client-screen-wrapper">
      <TherapistHeader />
      {therapists?.active_therapists.length !== 0 ||
      therapists?.invited_therapists.length !== 0 ? (
        <div className="client-search-result">
          {therapists?.active_therapists.map((data, index) => {
            return (
              <TherapistInfoTile
                key={index}
                last_session_date={data.last_session_date}
                therapist_id={data.therapist_id}
                therapy_id={data.therapy_id}
                name={data.therapist_name}
                designation={data.therapist_designation}
                therapist_accepted={true}
                profile_photo_url={data.profile_url}
              />
            );
          })}
          {therapists?.invited_therapists.map((data, index) => {
            return (
              <TherapistInfoTile
                key={index}
                invited_date={data.invited_date}
                therapist_id={data.therapist_id}
                therapy_id={data.therapy_id}
                name={data.therapist_name}
                designation={data.therapist_designation}
                therapist_accepted={false}
                profile_photo_url={data.profile_url}
              />
            );
          })}
        </div>
      ) : (
        <NotExist />
      )}
    </div>
  );
};

export default TherapistList;
