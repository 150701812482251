import { useState } from "react";
import "./RequestSessionRescheduleModal.css";

import ASSETS from "../../../../../assets/Assets";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import dayjs from 'dayjs';
import { format } from 'date-fns';

function RequestSessionRescheduleModal({ session, therapistEmail, onClose }) {

  const [startDatetime, setStartDatetime] = useState(dayjs(session.startDatetime));
  const [endDatetime, setEndDatetime] = useState(dayjs(session.endDatetime));

  const onReschedule = (e) => {
    // window.open(`mailto:${therapistEmail}?subject=Session Reschedule Request&body=Please reschedule request`);
    let subject = 'Session reschedule request'
    let body = 'Dear therapist,%0d%0a%0d%0aI am not available in the scheduled time slot.%0d%0a' +
    'Kindly reschedule the session to the ' + format(
      new Date(startDatetime), 'hh:mm aaa') + ' - ' + format(
        new Date(endDatetime), 'hh:mm aaa') + ' slot on ' + format(
          new Date(startDatetime), 'd MMM yyyy') + '.%0d%0a%0d%0a' +
    'Thanks%0d%0a'

    window.open(`mailto:${therapistEmail}?subject=${subject}&body=${body}`)
  };

  return <div className="modal" onClick={onClose}>
    <div className="modal-content" onClick={e => e.stopPropagation()}>
      <span className="close-modal-btn">
        <img src={ASSETS.modalCloseIcon} alt="close modal" onClick={onClose} />
      </span>
      <div className="modal-header">
        <div className="modal-title">
          <div className="modal-heading">Request Session Reschedule</div>
        </div>
      </div>
      <div className="modal-content-divider" />
      <div className="modal-body">
        <div className="reschedule-modal-input-row">
          <div className="reschedule-modal-input-group">
            <div className="modal-subheading sub-heading">Date</div>
            <div className="date-picker-container">
              <DatePicker format="DD-MM-YYYY"
                slotProps={{ textField: { size: 'small' } }}
                value={startDatetime}
                onChange={(newDate) => {
                  setStartDatetime(newDate);

                  const endDate = dayjs(
                    newDate.toISOString().split('T')[0] + 'T' +
                    endDatetime.toISOString().split('T')[1]);

                  setEndDatetime(endDate);
              }} />
            </div>
          </div>

          <div className="reschedule-modal-input-group">
            <div className="modal-subheading sub-heading">Time</div>
            <div className="time-picker-row">
              <div className="time-picker-container">
                <TimePicker slotProps={{ textField: { size: 'small' } }}
                  value={startDatetime}
                  onChange={(datetime) => setStartDatetime(datetime)} />
              </div>
              <div className="seperator">To</div>
              <div className="time-picker-container">
                <TimePicker slotProps={{ textField: { size: 'small' } }}
                  value={endDatetime}
                  onChange={(datetime) => setEndDatetime(datetime)} />
              </div>
            </div>
          </div>
        </div>
        <div className="reschedule-modal-reschedule-button" onClick={onReschedule}>Reschedule</div>
      </div>
    </div>
  </div>;
}

export default RequestSessionRescheduleModal;