import "./Dashboard.css";
// import "./Dashboard-tab.css";
import { useEffect, useState } from "react";
import Blogs from "./components/blogs/Blogs";
import DashboardHeader from "../../../components/dashboardHeader/DashboardHeader";
import MentalWellbeingStatus from "./components/mentalWellbeingStatus/MentalWellbeingStatus";
import Tasks from "./components/tasks/Tasks";
import Therapist from "./components/therapist/Therapist";
import useMediaQuery from "@mui/material/useMediaQuery";
import LastMeetingSummary from "./components/lastMeetingSummary/LastMeetingSummary";

import { getPatientDashboardByTherapyIdAPI } from "../../../api/patient";
import { updatePatientDashboard } from "../../../store/client/clientSlice";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

function Dashboard() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const therapyIdRedux = useSelector((store) => store.client.therapyId);

  useEffect(() => {
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const therapyId = params.get("therapy_id")
      ? params.get("therapy_id")
      : therapyIdRedux;

    getPatientDashboardByTherapyIdAPI(therapyId).then((res) => {
      dispatch(
        updatePatientDashboard({
          therapyId: therapyId,
          therapyStatus: res.data["therapy.status"],
          therapist: {
            name: res.data.name,
            designation: "Clinical Psychologist",
            profile: res.data.profile_url,
            rating: res.data.rating,
            connected_since: new Date().toISOString(),
            contact: res.data.contact_number,
            email: res.data.email,
          },
          upcoming_session: {
            sessionId: res.data.session_id,
            startDatetime: res.data.start_datetime_iso,
            endDatetime: res.data.end_datetime_iso,
            meetingLink: res.data.meeting_link,
          },
          wellbeing_status: 0, // Positive, Mixed and Distressed
          tasks: [],
        })
      );
      setIsLoading(false);
    });
  }, []);

  const tabView = useMediaQuery("(max-width:1440px)");
  return (
    <div className="dashboard-wrapper">
      {isLoading ? (
        <div className="ring-loader">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#5F6CE1"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="scroll-thin-vertical dashboard-left-wrapper">
            <DashboardHeader />
            <div className="client-dashboard-grid">
              <Therapist />
              {/* <MentalWellbeingStatus /> */}
              <LastMeetingSummary />
              {tabView && <Tasks />}
              <Blogs />
            </div>
          </div>
          {!tabView && <Tasks />}
        </>
      )}
    </div>
  );
}

export default Dashboard;
