import { createSlice } from "@reduxjs/toolkit";
import ASSETS from "../../assets/Assets";

const blogsSlice = createSlice({
  name: "blogs",
  initialState: [
    {
      banner: ASSETS.blogBanner1,
      title: "Remote Work and Metal Health",
      link: "https://medium.com/@imbesideyouinc/remote-work-and-mental-health-b1974a5a9491",
    },
    {
      banner: ASSETS.blogBanner2,
      title: "Finding Harmony: Strategies for Achieving Work-Life Balance",
      link: "https://medium.com/@imbesideyouinc/finding-harmony-strategies-for-achieving-work-life-balance-745110f7e6f4",
    },
    {
      banner: ASSETS.blogBanner3,
      title: "Unraveling the Truth about Remote Work Well-being",
      link: "https://medium.com/@imbesideyouinc/unraveling-the-truth-about-remote-work-well-being-4474bd70feea",
    },
    {
      banner: ASSETS.blogBanner4,
      title: "Effective Leadership in Response to Economic Growth Slowdown",
      link: "https://medium.com/@imbesideyouinc/effective-leadership-in-response-to-economic-growth-slowdown-9ac69b0ef291",
    },
  ],
});

export default blogsSlice.reducer;
