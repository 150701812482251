import "./MentalWellbeingStatus.css";

import ASSETS from "../../../../../assets/Assets";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchLatestMeetingAIAnalysisAPI } from "../../../../../api/meetingAIAnalysis";

function MentalWellbeingStatus() {
  const WELLBEING_TEXT = {
    1: "Might be experiencing positive emotions and psychological resilience. Just like a sunny day brightens the atmosphere, individuals might be feeling content, happy, and optimistic. They may have a positive outlook on life and possess a sense of overall well-being. Their emotional resilience helps them maintain a positive attitude and navigate through life's ups and downs.",
    0.5: "Might have a mix of positive and negative emotions, occasional stress, and a fluctuating psychological state. It suggests that the individual might be experiencing a range of emotions, both uplifting and challenging. They may feel moments of happiness, contentment, or even excitement, but they might also encounter occasional stress or periods of uncertainty.",
    0: "Might be experiencing significant distress, emotional difficulties, and challenges in multiple areas of life. This emoji suggests that the individual may be going through a particularly tough time, experiencing a range of negative emotions and difficulties. They might be feeling overwhelmed, sad, anxious, or even depressed.",
  };

  const getWellBeingStatus = (well_being_status) => {
    if (well_being_status <= 0.3) {
      return 0;
    } else if (well_being_status <= 0.7 && well_being_status > 0.3) {
      return 0.5;
    } else {
      return 1;
    }
  };

  const wellbeingStatusPics = {
    0: ASSETS.sunnyGIF,
    0.5: ASSETS.partlyCloudyGIF,
    1: ASSETS.cloudyGIF,
  };

  // const wellBeingStatus = useSelector((store) => store.client.wellbeing_status);
  // console.log(wellBeingStatus);

  const therapyId = useSelector((store) => store.client.therapyId);
  const [component, setComponent] = useState(0);
  const [wellBeingStatus, setWellBeingStatus] = useState(0);

  useEffect(() => {
    fetchLatestMeetingAIAnalysisAPI(therapyId).then((res) => {
      if (res.length) {
        setComponent(1);
        setWellBeingStatus(getWellBeingStatus(res[0]["well_being_status"]));
      } else {
        setComponent(0);
      }
    });
  }, [therapyId]);

  // 0 means Rainy
  // 1 Sunny

  return (
    <div className="mental-wellbeing-status">
      <div className="mental-wellbeing-subheading">My Under Weather Status</div>
      {component === 1 ? (
        <div className="mental-wellbeing-content">
          <img
            src={wellbeingStatusPics[wellBeingStatus]}
            alt="wellbeing status"
          />
          <div className="mental-wellbeing-content-text">
            {WELLBEING_TEXT[wellBeingStatus]}
          </div>
        </div>
      ) : (
        <div className="mental-wellbeing-content-placeholder">
          It will be available when your meeting has been analyszed
        </div>
      )}
    </div>
  );
}

export default MentalWellbeingStatus;
