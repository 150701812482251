import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ASSETS from "../../../../assets/Assets";
import "./AddFile.css";
import {
  createNewFileByTherapyAPI,
  getUploadFilePresignedUrlAPI,
} from "../../../../api/patient";
import { uploadFiles } from "../../../../api/s3";
import { toast } from "react-hot-toast";
import { ProgressBar } from "react-loader-spinner";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

function File({ file, index }) {
  return (
    <div
      className="overview-pinned-file upload-file"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="overview-pinned-file-type">
        <img src={ASSETS.pdfFile} alt="pdf file" />
      </div>
      <div className="overview-pinned-file-name">{file.name}</div>

      <div className="overview-pinned-file-footer row">
        <div className="overview-pinned-file-data">
          <div className="overview-pinned-file-size">
            {file.size > 1048576
              ? (file.size / 1048576).toFixed(1) + " mb"
              : (file.size / 1024).toFixed(1) + " kb"}
          </div>
        </div>
      </div>
    </div>
  );
}

function AddFile({ setAddFileMode }) {
  const therapyId = useSelector((store) => store.client.therapyId);
  const patientName = useSelector((store) => store.patient.name);
  const patientId = useSelector((store) => store.patient.patient_id);

  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [uploaded, setUploaded] = useState(new Set());
  const [loading, setLoading] = useState(false);

  const fileUpload = async (file) => {
    try {
      const res = await getUploadFilePresignedUrlAPI(
        therapyId,
        file.name.split(".").pop()
      );

      if (res.status === 200) {
        await uploadFiles(res.data.url, file);
        await createNewFileByTherapyAPI(
          therapyId,
          file.name,
          file.size,
          res.data.bucket,
          res.data.key,
          patientName,
          patientId
        );
        toast.success("file uploaded successfully.");
        const newUploaded = new Set();
        for (const item of uploaded.values()) {
          newUploaded.add(item);
        }
        newUploaded.add(file);
        setUploaded(newUploaded);
      }
    } catch (e) {
      toast.error("Upload failed");
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newfiles = e.dataTransfer.files;
    if (newfiles.length > 0) {
      setLoading(true);
      setFiles([...files, ...newfiles].slice(0, 6));
      const allFilesUpload = Array.from(newfiles).map((file) =>
        fileUpload(file)
      );
      await Promise.all([...allFilesUpload]);
      setLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleFileInput = async (e) => {
    if (e.target.files.length > 0) {
      setFiles([...files, ...e.target.files]);
      setLoading(true);
      const allFilesUpload = Array.from(e.target.files).map((file) =>
        fileUpload(file)
      );
      await Promise.all([...allFilesUpload]);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="add-file-content">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#F4442E"
          barColor="#51E5FF"
        />
      </div>
    );
  }

  return (
    <div
      className={"add-file-content " + (dragging ? "dragging-file" : "")}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <div className="file-upload-back-button">
        <IconButton
          aria-label="cross"
          onClick={() => {
            setAddFileMode(false);
          }}
        >
          <CancelIcon color="warning" />
        </IconButton>
      </div>
      <div className="report-files">
        {files.map((file, idx) => {
          return (
            <div className={"report-file"} key={idx}>
              <File file={file} />
            </div>
          );
        })}
      </div>
      <label
        htmlFor="uploadInput"
        className="report-files-header-add-files-button"
      >
        <img src={ASSETS.plusIcon} alt="plus icon" />
        Add File
      </label>
      <input
        type="file"
        id="uploadInput"
        onChange={handleFileInput}
        multiple="multiple"
      />
      <div className="add-file-content-info-text">or drop files here</div>
    </div>
  );
}

export default AddFile;
