import "./Tasks.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import {
  deleteTaskById,
  getAllTherapyTasksByTherapyId,
  updateTaskStatusById,
} from "../../../../api/patient";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ASSETS from "../../../../assets/Assets";
import {
  setTasks,
  updateCheckTask,
} from "../../../../store/report/reportSlice";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

export function Task(props) {
  let defaultChecked = !!props.task.status;
  console.log(defaultChecked);
  return (
    <div
      className="report-task row"
      onClick={() => {
        props.onCheckboxTick(props.task.task_id, props.idx);
      }}
    >
      <div className="report-task-text-group row justify-flex-start">
        <input type="checkbox" checked={defaultChecked} />
        <div className="report-task-text">{props.task.task}</div>
      </div>
      <div className="report-task-creation-date">
        {format(
          parseISO(mysqlDatetimeToJavascriptDatetime(props.task.created_at)),
          "EEE do MMM y"
        )}
      </div>
    </div>
  );
}

function Tasks() {
  const therapy_id = useSelector((store) => store.client.therapyId);
  const patient_id = useSelector((store) => store.patient.patient_id);
  const tasks = useSelector((store) => store.report.tasks);
  const dispatch = useDispatch();

  // let [tasks, setTasks] = useState([]);
  // let [tasksMetrics, setTasksMetrics] = useState([]);

  useEffect(() => {
    getAllTherapyTasksByTherapyId(therapy_id).then((data) => {
      let all_tasks = data.all_therapy_tasks.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      dispatch(setTasks(all_tasks));
    });
  }, []);

  function onCheckboxTick(task_id, idx) {
    const status = tasks[idx].status ? 0 : 1;
    updateTaskStatusById(task_id, status).then((res) => {
      dispatch(
        updateCheckTask({
          idx,
          status,
        })
      );
    });
  }

  const totalTasks = tasks.length;
  const completedTasks = tasks.filter((element) => element.status).length;
  const remainingTasks = totalTasks - completedTasks;

  return (
    <>
      <div className="report-tasks-container">
        <div className="report-task-and-advise-summary">
          <div className="report-task-and-advise-summary-subheading row">
            <div className="report-task-and-advise-summary-pasteboard">
              <img src={ASSETS.tasksAndAdvice} alt="tasks and advice" />
            </div>
            <div className="report-task-and-advise-summary-subheading-text">
              Tasks & Advise Given Summary
            </div>
          </div>
          <div className="report-tasks-metrics">
            <div className="report-task-metric">
              <div className="report-task-metric-value">{totalTasks}</div>
              <div className="report-task-metric-text">Total Tasks Given</div>
            </div>
            <div className="report-task-metric">
              <div className="report-task-metric-value">{completedTasks}</div>
              <div className="report-task-metric-text">Tasks Completed</div>
            </div>
            <div className="report-task-metric">
              <div className="report-task-metric-value">{remainingTasks}</div>
              <div className="report-task-metric-text">Tasks Incomplete</div>
            </div>
          </div>
        </div>
        <div className="report-tasks">
          {tasks.map((task, idx) => {
            return (
              <Task idx={idx} task={task} onCheckboxTick={onCheckboxTick} />
            );
            // return <div className="report-task row" key={idx}>
            //   <div className="report-task-text-group row justify-flex-start">
            //     <input type="checkbox" checked={task.status === "1" ? true : false} onChange={(event) => onCheckboxTick(event, task.task_id, idx)} />
            //     <div className="report-task-text">
            //       {task.task}
            //     </div>
            //   </div>
            //   <DeleteIcon sx={{ color: "#282860", opacity: "0.5", cursor: 'pointer' }} onClick={() => onDeleteButtonClick(idx)} />
            //   <div className="report-task-creation-date">{(new Date(task.created_at)).toDateString()}</div>
            // </div>;
          })}
        </div>
      </div>
    </>
  );
}

export default Tasks;
