import { createSlice } from "@reduxjs/toolkit";
import ASSETS from "../../assets/Assets";

const therapistSlice = createSlice({
  name: 'therapist',
  initialState: {
    active_clients: 0,
    handoffs: 0,
    num_sessions: 0,
    reputation: {
      clients: [
        {
          profile: ASSETS.cameraAlt,
        },
        {
          profile: ASSETS.client2,
        },
        {
          profile: ASSETS.client3,
        },
        {
          profile: ASSETS.client4,
        },
        {
          profile: ASSETS.client5,
        },
        {
          profile: ASSETS.client6,
        },
        {
          profile: ASSETS.client7,
        },
        {
          profile: ASSETS.client8,
        }
      ],
      rating: 5
    },
    client_sessions: [
      {
        name: 'Juhi Sharma',
        profile: ASSETS.cameraAlt,
        connected_since: (new Date()).toISOString(),
        notes: [
          {
            date: (new Date()).toISOString(),
            note: "Based on the AI analysis of the session with Juhi, today's discussion primarily focused on addressing her work-related stress and its impact on her overall well-being. The analysis revealed that Juhi has been experiencing high levels of stress and is showing signs of burnout. The session aimed to explore the underlying causes of her stress, develop effective stress management techniques, and promote a healthier work-life balance. During the session, Juhi expressed feelings of overwhelm and shared specific challenges she faces in her professional life. The AI analysis identified key factors contributing to her stress, such as excessive workload, lack of boundaries, and ineffective communication in the workplace. Strategies were discussed to help Juhi cope with stress and regain control over her work environment."
          },
          {
            date: (new Date()).toISOString(),
            note: "Based on the AI analysis of the session with Juhi, today's discussion primarily focused on addressing her work-related stress and its impact on her overall well-being. The analysis revealed that Juhi has been experiencing high levels of stress and is showing signs of burnout. The session aimed to explore the underlying causes of her stress, develop effective stress management techniques, and promote a healthier work-life balance. During the session, Juhi expressed feelings of overwhelm and shared specific challenges she faces in her professional life. The AI analysis identified key factors contributing to her stress, such as excessive workload, lack of boundaries, and ineffective communication in the workplace. Strategies were discussed to help Juhi cope with stress and regain control over her work environment."
          }
        ],
        status: 'upcoming', // upcoming, completed (ai analysis) and missed.
      },
      {
        name: 'Rahul Dev',
        profile: ASSETS.client2,
        connected_since: (new Date()).toISOString(),
        notes: [
          {
            date: (new Date()).toISOString(),
            note: "Based on the AI analysis of the session with Rahul, today's discussion primarily focused on addressing her work-related stress and its impact on her overall well-being. The analysis revealed that Juhi has been experiencing high levels of stress and is showing signs of burnout. The session aimed to explore the underlying causes of her stress, develop effective stress management techniques, and promote a healthier work-life balance. During the session, Juhi expressed feelings of overwhelm and shared specific challenges she faces in her professional life. The AI analysis identified key factors contributing to her stress, such as excessive workload, lack of boundaries, and ineffective communication in the workplace. Strategies were discussed to help Juhi cope with stress and regain control over her work environment."
          }
        ],
        status: 'upcoming', // upcoming, completed (ai analysis) and missed.
      },
      {
        name: 'Jia Sharma',
        profile: ASSETS.client9,
        connected_since: (new Date()).toISOString(),
        notes: [
          {
            date: (new Date()).toISOString(),
            note: "Based on the AI analysis of the session with Jia, today's discussion primarily focused on addressing her work-related stress and its impact on her overall well-being. The analysis revealed that Juhi has been experiencing high levels of stress and is showing signs of burnout. The session aimed to explore the underlying causes of her stress, develop effective stress management techniques, and promote a healthier work-life balance. During the session, Juhi expressed feelings of overwhelm and shared specific challenges she faces in her professional life. The AI analysis identified key factors contributing to her stress, such as excessive workload, lack of boundaries, and ineffective communication in the workplace. Strategies were discussed to help Juhi cope with stress and regain control over her work environment."
          }
        ],
        status: 'completed', // upcoming, completed (ai analysis) and missed.
      },
    ],
  }
})

export default therapistSlice.reducer;