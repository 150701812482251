import { createBrowserHistory } from "history";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

// TODO: import all reducers down below here.
import blogsSliceReducer from "./blogs/blogsSlice";
import clientSliceReducer from "./client/clientSlice";
import reportSliceReducer from "./report/reportSlice";
import sessionsSliceReducer from "./sessions/sessionsSlice";
import therapistSliceReducer from "./therapist/therapistSlice";
import activitySliceReducer from "./activities/activitySlice";
import patientSliceReducer from "./patient/patientSlice";

export const history = createBrowserHistory();

const combinedReducers = combineReducers({
  blogs: blogsSliceReducer,
  client: clientSliceReducer,
  report: reportSliceReducer,
  sessions: sessionsSliceReducer,
  therapist: therapistSliceReducer,
  activities: activitySliceReducer,
  patient: patientSliceReducer,
});

// combineReducers will be handled internally by configureStore
const rootReducer = (state, action) => {
  if (action.type === "patient/logoutResetState") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

export default store;
