import "./Files.css";

import { useEffect, useState } from "react";
import ASSETS from "../../../../assets/Assets";
import PinnedFiles from "../pinnedFiles/PinnedFiles";
import AddFile from "../addFile/AddFile";
import { useDispatch, useSelector } from "react-redux";
import { getTherapyFilesAPI } from "../../../../api/patient";
import { setFiles } from "../../../../store/report/reportSlice";
import { MagnifyingGlass } from "react-loader-spinner";

function Files() {
  const [addFileMode, setAddFileMode] = useState(false);
  const therapyId = useSelector((store) => store.client.therapyId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchTherapyFiles = async () => {
    setLoading(true);
    const res = await getTherapyFilesAPI(therapyId);
    const files = res.data.map((file) => {
      return {
        fileId: file.file_id,
        filename: file.filename,
        uploaded_at: file.uploaded_at_iso,
        fileUrl: file.file_url,
        size: file.size,
        author: file.author_profile_url,
        pinned: file.pinned !== 0,
      };
    });
    dispatch(setFiles(files));
    setLoading(false);
  };

  useEffect(() => {
    if (!addFileMode) fetchTherapyFiles();
  }, [addFileMode]);

  return addFileMode ? (
    <AddFile setAddFileMode={setAddFileMode} />
  ) : (
    <div className="report-files-container">
      <div className="report-files-header row">
        <div className="report-files-header-infotext">
          These files are shared with your helper as well.
        </div>
        <div
          className="report-files-header-add-files-button"
          onClick={(e) => setAddFileMode(true)}
        >
          <img src={ASSETS.plusIcon} alt="plus icon" />
          Add File
        </div>
      </div>
      <div className="report-both-pinned-and-pinned-files">
        {loading && (
          <MagnifyingGlass
            visible={true}
            height="80"
            width="80"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#e15b64"
          />
        )}
        {!loading && (
          <>
            <PinnedFiles />
            <div className="report-files-all-files-section">
              <PinnedFiles pinned={false} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Files;
