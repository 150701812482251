import React from "react";
import { AccountSettings } from "@aws-amplify/ui-react";
import { toast } from "react-hot-toast";
import "./PasswordSettings.css";

function PasswordSettings() {
  const minLength = {
    validationMode: "onChange",
    validator: (password) => password.length >= 8,
    message: "Password must have length 8 or greater",
  };

  const handleSuccess = () => {
    toast.success("password is successfully changed!");
  };

  return (
    <div className="password-settings-wrapper">
      <div className="heading">Reset Password</div>
      <div className="password-settings-form">
        <AccountSettings.ChangePassword
          onSuccess={handleSuccess}
          validators={[minLength]}
        />
      </div>
    </div>
  );
}

export default PasswordSettings;
