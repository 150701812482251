import { createSlice } from "@reduxjs/toolkit";
import ASSETS from "../../assets/Assets";

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: [
    // {
    //   client_name: 'Juhi Sharma',
    //   client_profile: ASSETS.cameraAlt,
    //   number: 2,
    //   status: 'Coming Up',
    //   startTime: (new Date()).toISOString(),
    //   endTime: (new Date()).toISOString(),
    //   session_link: '',
    //   ai_analysis_link: ''
    // },
    // {
    //   client_name: 'Rahul Dev',
    //   client_profile: ASSETS.client2,
    //   number: 20,
    //   status: 'Completed',
    //   startTime: (new Date()).toISOString(),
    //   endTime: (new Date()).toISOString(),
    //   link: '',
    //   ai_analysis_link: '/therapist/clients'
    // },
    // {
    //   client_name: 'Jia Joel',
    //   client_profile: ASSETS.client9,
    //   number: 15,
    //   status: 'Missed',
    //   startTime: (new Date()).toISOString(),
    //   endTime: (new Date()).toISOString(),
    //   session_link: '',
    //   ai_analysis_link: ''
    // }
  ],
  reducers: {
    addSession(state, action) {
      state.push(action.payload);
    },
    setSessions(state, action) {
      return action.payload;
    }
  }
});

export const { addSession, setSessions } = sessionsSlice.actions;

export default sessionsSlice.reducer;