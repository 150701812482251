import "./AllSessions.css";

import { Link, useSearchParams } from "react-router-dom";

import ASSETS from "../../../../assets/Assets";
import { useSelector } from "react-redux";

import { format, setMilliseconds } from "date-fns";
import { useEffect, useState } from "react";
import { fetchAllMeetingAIAnalysisAPI } from "../../../../api/meetingAIAnalysis";
import Session from "../session/Session";
import SessionsTimeline from "./SessionsTimeline";

function AllSessions() {
  //const sessions = useSelector((store) => store.report.sessions);
  const therapyId = useSelector((store) => store.client.therapyId);

  //const patinetId = searchParams.get("patient_id");

  // const [index, setIndex] = useState(0);
  // const [videoId, _setVideoId] = useState("");
  const [currentSession, setCurrentSession] = useState(null);

  //const therapyId = useSelector((store) => store.client.therapyId);
  const [sessions, setSessions] = useState([]);
  //const therapyId = "";

  useEffect(() => {
    fetchAllMeetingAIAnalysisAPI(therapyId).then((res) => {
      setSessions(res);
    });
  }, [therapyId]);

  return (
    <div className="report-ai-analysis">
      <div className="report-ai-analysis-pinned">All Sessions</div>
      <div className="row justify-flex-start"></div>
      <SessionsTimeline sessions={sessions} />
    </div>
  );
}

export default AllSessions;
