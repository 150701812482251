import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const fetchAllMeetingAIAnalysisAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/therapy_analysis?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const fetchLatestMeetingAIAnalysisAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/latest_analysis?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const fetchMeetingSummaryAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/summary?video_id=${video_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const fetchMeetingPatientEmotionsAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = `/meetings/patient_emotions?video_id=${video_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};
