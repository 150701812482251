import React, { useState } from "react";
import "./EmailSettings.css";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientEmailAPI } from "../../../api/patient";
import toast from "react-hot-toast";
import { Auth } from "aws-amplify";
import { updatePatientEmail } from "../../../store/patient/patientSlice";

function EmailSettings() {
  const patient = useSelector((store) => store.patient);
  const dispatch = useDispatch();
  // const { user } = useAuthenticator((selector) => selector.user);
  const [email, setEmail] = useState();
  const updateUserEmail = async () => {
    try {
      let res = await updatePatientEmailAPI(patient.email, email);
      if (res.status === 200) {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        dispatch(updatePatientEmail(email));
        toast.success("Email updated");
      } else {
        throw Error("Error in updating email");
      }
    } catch (err) {
      toast.error("Error in updating email");
    }
  };
  return (
    <div className="email-settings-wrapper">
      <div className="heading">Change Email</div>
      <div className="email-settings-form">
        <TextField
          name="email"
          placeholder="Eg. recipient@email.com"
          fullWidth
          label="Change Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div
          className="blue-button"
          onClick={() => {
            updateUserEmail();
          }}
        >
          Update Email
        </div>
      </div>
    </div>
  );
}

export default EmailSettings;
