import "./Therapist.css";

import StarRating from "../../../../../components/starRating/StarRating";
import UpcomingSession from "../upcomingSession/UpcomingSession";
import { useSelector } from "react-redux";

import { format } from 'date-fns';

function Therapist() {
  const therapist = useSelector(store => store.client.therapist);

  return <div className="therapist-row">
    <div className="therapist-profile">
      <img src={therapist.profile} alt="therapist" />
    </div>
    <div className="therapist-info">
      <div className="therapist-name">{ therapist.name }</div>
      <div className="therapist-designation">{ therapist.designation }</div>

      <StarRating rating={therapist.rating} />

      <div className="therapist-info-field row justify-flex-start">
        <div className="therapist-info-field-name">Connected Since</div>
        <div className="therapist-info-field-value">{ format(new Date(therapist.connected_since), 'd MMM y') }</div>
      </div>
      {/* <div className="therapist-info-field row justify-flex-start">
        <div className="therapist-info-field-name">Contact Number</div>
        <div className="therapist-info-field-value">{ therapist.contact }</div>
      </div>
      <div className="therapist-info-field row justify-flex-start">
        <div className="therapist-info-field-name">Email ID</div>
        <div className="therapist-info-field-value">{ therapist.email }</div>
      </div> */}
    </div>

    <UpcomingSession />
  </div>;
}

export default Therapist;
